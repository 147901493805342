<template>
  <div class="order_complete">
    <h2>Bestellung erfolgreich</h2>
    <p>Du erhältst eine Bestätigung deiner Bestellung auf deine hinterlegte Mailadresse 
      <span class="text_highlight">{{store.userData.email}}</span>.
<br>
<br>
Wir bemühen uns dir dein(e) Shirt(s) so schnell wie möglich zu schicken.<br> 
Bitte hab aber Verständnis dafür, dass wir das Ganze komplett ehrenamtlich und in unserer Freizeit machen, also kein professionelles Warenhaus sind. Der Versand kann daher auch mal etwas dauern.</p>
<h3>Hast du noch Fragen zu deiner Bestellung</h3>
<div class="button shadow-box" v-on:click="openPage('delivery')">
    Versandkosten & Lieferzeiten
</div>
<div class="button shadow-box" v-on:click="openPage('contact')">
    Kontakt
</div>

<h3>Du willst wissen wo das Geld hingeht?</h3>
<div class="button shadow-box" v-on:click="openPage('projects')">
    Projekte
</div>

<h3>Du willst wissen was Wir sonst so machen?</h3>
<div class="button shadow-box" v-on:click="openPage('news')">
    Aktuelles
</div>

  </div>
</template>

<script>
import store from '@/store/store.js';
import apiService from '@/services/apiService.js';

export default {
  name: 'Home',
  components: {
    
  },
  data() {
    return {
      store,
    };
  },
  methods:{
    openPage(url){
      this.$router.push(url);
    }
  },
  mounted () {
    console.log("mounted");
    apiService.saveOrderData();
    apiService.loadProductsData(false);
  }
}
</script>

<style lang="scss">
  .order_complete{
    text-align: left;
    padding: $outer-padding;
    margin-top: 100px;
    margin-bottom: 50px;
    @media only screen and (min-width: 612px){ 
      max-width: 750px;
      margin: auto;
      margin-top: 100px;
      margin-bottom: 50px;
    }
    h2{
      padding-left: 0;
      padding-right: 0;
      line-height: 50px;
    }
    p{
      line-height: 22px;
    }
    h3{
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0px;
      padding-top: 50px;
    }
    .button{
      width: 100%;
      display: block;
      margin-top: 10px;
    }
  }
</style>
